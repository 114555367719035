import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/opt/build/repo/src/templates/blog-post.js"

export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = DefaultLayout
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             Layout={this.layout} layoutProps={props}
             components={components}>

<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"https://jestjs.io/"}}>{`Jest`}</MDXTag>{` can generate test coverage reports
using `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://istanbul.js.org/"}}>{`Istanbul`}</MDXTag>{` by passing the
`}<code className="language-text">--coverage</code>
{` flag. If all goes well, you’ll see a neat report
like this on the terminal:`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="img" components={components} parentName="p" props={{"src":"/coverage-1.png","alt":"Terminal Coverage Report"}}></MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`The report contains useful pointers to things like uncovered
lines (highlighted in red), or uncovered branches (highlighted
in yellow).`}</MDXTag>
<MDXTag name="p" components={components}>{`Even after fixing problems, you might hit the odd pesky function
that isn’t covered resulting in a less than perfect test coverage
score.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="img" components={components} parentName="p" props={{"src":"/coverage-2.png","alt":"Terminal Coverage Report"}}></MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`This might happen if you use a default function as a fallback
(see below) and you don’t test the case where `}<MDXTag name="em" components={components} parentName="p">{`callback`}</MDXTag>{` is
`}<MDXTag name="em" components={components} parentName="p">{`undefined`}</MDXTag>{`.`}</MDXTag>
<div className="gatsby-highlight" data-language="javascript"><pre style={{counterReset: 'linenumber NaN'}} className="language-javascript line-numbers"><code className="language-javascript"><span className="token keyword">function</span> <span className="token function">Main</span><span className="token punctuation">(</span>callback<span className="token punctuation">)</span> <span className="token punctuation">{"{"}</span>{"\n"}{"  "}<span className="token keyword">this</span><span className="token punctuation">.</span>callback <span className="token operator">=</span> callback <span className="token operator">||</span> <span className="token punctuation">(</span><span className="token punctuation">)</span> <span className="token operator">=&gt;</span> <span className="token punctuation">{"{"}</span><span className="token punctuation">{"}"}</span><span className="token punctuation">;</span>{"\n"}{"  "}<span className="token operator">...</span>{"\n"}<span className="token punctuation">{"}"}</span></code><span aria-hidden="true" className="line-numbers-rows" style={{whiteSpace: 'normal', width: 'auto', left: 0}}><span /><span /><span /><span /></span></pre></div>

<MDXTag name="p" components={components}>{`The way to identify these coverage gaps is to use the HTML
reporter. The HTML report is generated in `}<MDXTag name="em" components={components} parentName="p">{`./coverage/lcov-report`}</MDXTag>{`. You
can spin up a HTTP server to see the report like this:`}</MDXTag>
<div className="gatsby-highlight" data-language="text"><pre style={{counterReset: 'linenumber NaN'}} className="language-text line-numbers"><code className="language-text">$ npm install --global http-server{"\n"}$ http-server coverage/lcov-report</code><span aria-hidden="true" className="line-numbers-rows" style={{whiteSpace: 'normal', width: 'auto', left: 0}}><span /><span /></span></pre></div>

<MDXTag name="p" components={components}>{`It will overlay coverage statistics on the actual source code so that you can identify problems more easily.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="img" components={components} parentName="p" props={{"src":"/coverage-3.png","alt":"HTML Coverage Report"}}></MDXTag></MDXTag>
           </MDXTag>
  }
}

export const _frontmatter = {};

  